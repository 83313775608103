import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import { replaceSpacesWithDash } from "./utils";

class ResourceRollTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    let products = posts.filter(
      (post) => post.node.frontmatter.templateKey === "product"
    );
    const resources = posts.filter(
      (post) => post.node.frontmatter.templateKey === "resource"
    );

    // creating map of resource titles to their links for later use
    const mapOfResourceTitles = {};
    resources.forEach((resource) => {
      mapOfResourceTitles[resource.node.frontmatter.title] = {
        link: resource.node.frontmatter.link,
        mediatype: resource.node.frontmatter.mediatype,
        title: resource.node.frontmatter.title,
      };
    });

    products.map((product) => {
      product.node.resources = [];
      if (product.node.frontmatter.resources !== null) {
        product.node.frontmatter.resources.map((resource) => {
          let res = mapOfResourceTitles[resource];
          if (res !== undefined) {
            product.node.resources.push(mapOfResourceTitles[resource]);
          }
          return { ...resource };
        });
      }
      return { ...product };
    });

    products.forEach((product) => {
      product.node.resources.sort((a, b) => {
        if (a.mediatype < b.mediatype) {
          return 1;
        }
        if (a.mediatype > b.mediatype) {
          return -1;
        }
        return 0;
      });
    });

    products = products.filter((product) => product.node.resources.length > 0);

    return (
      <React.Fragment>
        {products &&
          products.map((product) => (
            <div
              id={replaceSpacesWithDash(product.node.frontmatter.title)}
              key={product.node.frontmatter.title}
            >
              <h1 style={{ marginBottom: "20px" }}>
                {product.node.frontmatter.title}
              </h1>
              <table
                className="table table-striped resource-table"
                style={{ tableLayout: "fixed" }}
              >
                <tbody>
                  {product.node.resources.map((resource, i) => {
                    return (
                      <tr key={i}>
                        <td
                          colSpan={1}
                          className="table-cell-striped"
                          style={{ verticalAlign: "middle", width: "100px" }}
                        >
                          <a
                            href={resource.link}
                            target="blank"
                            rel="noopener noreferrer"
                          >
                            <i className={resource.mediatype}></i>
                          </a>
                        </td>
                        <td
                          colSpan={2}
                          className="table-cell-striped"
                          style={{ verticalAlign: "middle" }}
                        >
                          <div>
                            <a
                              href={resource.link}
                              target="blank"
                              rel="noopener noreferrer"
                            >
                              {resource.title}
                            </a>
                          </div>
                        </td>
                        <td
                          colSpan={1}
                          align="right"
                          className="table-cell-striped"
                          style={{ verticalAlign: "middle" }}
                        >
                          <a
                            href={resource.link}
                            style={{ color: "white" }}
                            target="blank"
                            rel="noopener noreferrer"
                          >
                            <button type="button" className="btn btn-warning">
                              OPEN
                            </button>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ))}
      </React.Fragment>
    );
  }
}

ResourceRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function ResourceRoll() {
  return (
    <StaticQuery
      query={graphql`
        query ResourceRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: {
              frontmatter: { templateKey: { in: ["product", "resource"] } }
            }
          ) {
            edges {
              node {
                html
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  images {
                    childImageSharp {
                      gatsbyImageData(
                        width: 120
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                  related_product
                  link
                  mediatype
                  resources
                  phase
                  stage
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <ResourceRollTemplate data={data} count={count} />
      )}
    />
  );
}
